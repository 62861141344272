import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '@/styles/atoms/HighlightedText/styles.module.css'

const MAX_LENGTH = 36

const HighlightedText = ({children, big}) => {
  function getText() {
    return children && children.length > MAX_LENGTH
      ? `${children.substring(0, MAX_LENGTH)}...`
      : children
  }

  return (
    <span className={classNames(styles.ecHighlightedText)}>
      <span className={styles.ecHighlightedText__text}>{getText()}</span>
      <span
        className={classNames(styles.ecHighlightedText__highlight, {
          [styles.ecHighlightedText__highlight_big]: big
        })}
      />
    </span>
  )
}

HighlightedText.propTypes = {
  children: PropTypes.string.isRequired,
  big: PropTypes.bool
}

export default HighlightedText
