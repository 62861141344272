import PropTypes from 'prop-types'
import classNames from 'classnames'
import Slider from '@/components/shared/Slider'
import Anchor from '@/components/shared/Anchor'
import Button from '@/components/shared/Button'
import styles from '@/styles/organisms/LandingList/styles.module.css'

const Item = ({item, index}) => {
  return (
    <>
      {item.icon ? (
        <img
          className={classNames(styles.ecLandingList__item__icon, {
            [styles.ecLandingList__item__icon_big]: item.iconBig,
            [styles.ecLandingList__item__icon_bigger]: item.iconBigger
          })}
          src={item.icon}
          alt="Ícone"
          width={item.iconBig ? 140 : item.iconBigger ? 180 : 120}
          height={item.iconBig ? 72 : item.iconBigger ? 152 : 36}
        />
      ) : (
        <span className={styles.ecLandingList__item__index}>{index + 1}</span>
      )}
      {item.title && (
        <h4 className={styles.ecLandingList__item__title}>{item.title}</h4>
      )}
      {item.text && (
        <p className={styles.ecLandingList__item__text}>{item.text}</p>
      )}
      {item.link && (
        <Anchor
          to={item.link.to}
          href={item.link.href}
          target={item.link.target}
          rel={item.link.target ? 'external noopener noreferrer' : ''}
          onClick={item.link.onClick}
        >
          {item.link.label}
        </Anchor>
      )}
      {item.button && (
        <Button {...item.button.props} onClick={item.button.onClick}>
          {item.button.label}
        </Button>
      )}
    </>
  )
}

const LandingList = ({name, oddColumn, list, afterChange}) => {
  return (
    <Slider
      name={name}
      list={list.map((item, index) => {
        return (
          <div
            className={classNames(styles.ecLandingList__item, {
              [styles.ecLandingList__item_odd]: oddColumn
            })}
            key={index}
          >
            <Item key={index} index={index} item={item} />
          </div>
        )
      })}
      settings={{
        arrows: false,
        dots: false,
        className: oddColumn ? 'ecLandingList__oddList' : 'ecLandingList__list',
        slidesToShow: list.length,
        slidesToScroll: list.length,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              arrows: true,
              dots: true,
              slidesToScroll: 1
            }
          }
        ],
        afterChange: afterChange || null
      }}
    />
  )
}

export const LandingListSimple = ({oddColumn, list}) => {
  return (
    <div className={styles.ecLandingList}>
      <ul className={styles.ecLandingList__list_simple}>
        {list.map((item, index) => {
          return (
            <li
              className={classNames(styles.ecLandingList__item, {
                [styles.ecLandingList__item_odd]: oddColumn
              })}
              key={index}
            >
              <Item key={index} index={index} item={item} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

LandingList.propTypes = {
  name: PropTypes.string.isRequired,
  oddColumn: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      iconBig: PropTypes.bool,
      iconBigger: PropTypes.bool,
      title: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      link: PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func
      }),
      button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        props: PropTypes.object,
        onClick: PropTypes.func
      })
    })
  ).isRequired,
  afterChange: PropTypes.func
}

export default LandingList
