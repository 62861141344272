import LandingSectionVideo from '../../components/shared/LandingSection/contents/Video'
import {EMCASA_ABOUT_VIDEO} from '@/pages/about/constants'
import HighlightedText from '@/components/shared/HighlightedText'
import styles from '@/styles/organisms/LandingSection/styles.module.css'

export const SPECIALISTS_VIDEO =
  'https://www.youtube.com/embed/qM8anRgtIoM?rel=0'

export const loggerAction = {
  onload: 'specialists-page',
  lss: 'specialists-page-lss-button'
}

export const HEADHELMET = {
  title: 'Especialistas de venda | EmCasa',
  description:
    'Os especialistas de venda da EmCasa vão te ajudar a encontrar o imóvel ideal.',
  url: 'https://emcasa.com/',
  image: `${process.env.CLOUDINARY_IMAGE_URL}/share/share-6`
}

export const LANDINGHEADER = {
  title: (
    <>
      É muito mais prático comprar seu imóvel com um{' '}
      <HighlightedText big={true}>Especialista Imobiliário</HighlightedText>
    </>
  ),
  text: (
    <>
      Na EmCasa, te guiamos da busca à entrega das chaves,{' '}
      <strong>de forma transparente e segura.</strong>
    </>
  )
}

export const LANDINGSECTION_STEPS = {
  label: 'Mais praticidade',
  title: (
    <>
      Nossos especialistas te guiam da busca,
      <br /> até a entrega das chaves.
    </>
  ),
  list: [
    {
      icon: '/img/illustrations/woman-laptop-man-laptop.svg',
      iconBigger: true,
      title: 'Entendemos o que você precisa e encontramos para você',
      text:
        'Os Especialistas Imobiliários EmCasa buscam o imóvel ideal para você, mesmo que ele ainda não esteja no nosso site.'
    },
    {
      icon: '/img/illustrations/couple-woman-handbag.svg',
      iconBigger: true,
      title: 'Te acompanhamos antes, durante e depois da visita',
      text:
        'Nosso time é treinado para guiar suas visitas de forma prática e transparente. Você pode tirar todas as suas dúvidas, antes de comprar o seu imóvel.'
    },
    {
      icon: '/img/illustrations/man-woman-laptop.svg',
      iconBigger: true,
      title: 'Te entregamos as chaves com toda a burocracia resolvida.',
      text:
        'O Especialista Imobiliário que te acompanhou negocia com o proprietário, faz o levantamento de toda a documentação, agiliza a aprovação de crédito para financiamento imobiliário e está com você até a assinatura do contrato.'
    }
  ]
}

export const LANDINGSECTION_SPECIALISTS = {
  label: 'O Especialista',
  title: 'Reinventando o papel do corretor tradicional',
  contents: [
    <div className={styles.ecLandingSection__asset_onlymobile}>
      <LandingSectionVideo src={EMCASA_ABOUT_VIDEO} />
    </div>,
    <p>
      Nosso time de Especialistas Imobiliários é formado por colaboradores
      próprios, com carteira assinada, salário fixo e mais de 100h de
      treinamentos. Com isso, todo o nosso foco está em atender exclusivamente o
      seu interesse, buscando sem compromisso e respeitando seu tempo.
    </p>
  ],
  contentLeft: (
    <div className={styles.ecLandingSection__asset_onlydesktop}>
      <LandingSectionVideo src={EMCASA_ABOUT_VIDEO} />
    </div>
  )
}
