import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '@/styles/organisms/LandingHeader/styles.module.css'

const LandingHeader = ({
  small,
  nudeTheme,
  greyTheme,
  title,
  text,
  contents,
  footer,
  background
}) => {
  const landingHeaderClassName = classNames(styles.ecLandingHeader, {
    [styles.ecLandingHeader_nude]: nudeTheme,
    [styles.ecLandingHeader_grey]: greyTheme,
    [styles.ecLandingHeader_withBackgroundImage]: background
  })
  const contentClass = classNames(styles.ecLandingHeader__content, {
    [styles.ecLandingHeader__content_small]: small,
    [styles.ecLandingHeader__content_withBackgroundImage]: background
  })
  const textClassName = classNames(styles.ecLandingHeader__text, {
    [styles.ecLandingHeader__text_withoutContent]: !contents,
    [styles.ecLandingHeader__text_withBackgroundImage]: background
  })
  return (
    <div className={landingHeaderClassName}>
      <div className={contentClass}>
        {title && <h1 className={styles.ecLandingHeader__title}>{title}</h1>}
        {text && <p className={textClassName}>{text}</p>}
        {contents &&
          contents.map((item, index) => (
            <div className={styles.ecLandingHeader__item} key={index}>
              {item}
            </div>
          ))}
        {footer && (
          <div className={styles.ecLandingHeader__footer}>{footer}</div>
        )}
      </div>
      {background && (
        <img
          className={styles.ecLandingHeader__backgroundImage}
          src={background?.src}
          width={background?.width}
          height={background?.height}
          alt={background?.alt}
          style={
            process.browser
              ? {
                  '--objectPosition': background?.position || '50% 75%'
                }
              : {}
          }
        />
      )}
    </div>
  )
}

LandingHeader.propTypes = {
  small: PropTypes.bool,
  nudeTheme: PropTypes.bool,
  greyTheme: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contents: PropTypes.arrayOf(PropTypes.object),
  footer: PropTypes.object,
  background: PropTypes.shape({
    src: PropTypes.string.isRequired,
    position: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    alt: PropTypes.string.isRequired
  })
}

export default LandingHeader
