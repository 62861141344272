import Proptypes from 'prop-types'
import logger from '@/lib/logger'
import classNames from 'classnames'
import styles from '@/styles/organisms/Slider/styles.module.css'
import Carousel from 'react-slick'

function NavArrow(props) {
  const {onClick, prev, big} = props
  return (
    <button
      className={classNames(styles.ecSlider__slickSlider__arrow, {
        [styles.ecSlider__slickSlider__arrow_prev]: prev,
        [styles.ecSlider__slickSlider__arrow_big]: big
      })}
      onClick={onClick}
    >
      <img
        className={classNames(styles.ecSlider__slickSlider__arrow__icon, {
          [styles.ecSlider__slickSlider__arrow__icon_big]: big
        })}
        src="/img/icons/arrow-x-pink.svg"
        alt="Ícone"
        width="20"
        height="20"
      />
    </button>
  )
}

const Slider = ({list, settings, name, darkTheme, bigArrowOnDesktop}) => {
  function handleAfterChange(current) {
    logger.action('slider-change', {name, slideIndex: current})
  }

  const defaultSettings = {
    arrows: true,
    dots: true,
    infinite: false,
    easing: 'ease-out',
    speed: 580,
    focusOnSelect: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NavArrow big={bigArrowOnDesktop} />,
    prevArrow: <NavArrow big={bigArrowOnDesktop} prev={true} />,
    afterChange: handleAfterChange,
    customPaging: (i) => (
      <button className={styles.ecSlider__slickSlider__button}>{i + 1}</button>
    )
  }

  const sliderClassName = [settings && settings.className]
  if (darkTheme) {
    sliderClassName.push('ecSlider_dark')
  }

  const carouselSettings = {
    ...defaultSettings,
    ...settings,
    className: sliderClassName.join(' ') || ''
  }

  return (
    <div className={styles.ecSlider}>
      <Carousel {...carouselSettings}>{list}</Carousel>
    </div>
  )
}

Slider.propTypes = {
  settings: Proptypes.object,
  name: Proptypes.string.isRequired,
  list: Proptypes.array.isRequired,
  sliderClassName: Proptypes.string,
  darkTheme: Proptypes.bool
}

export default Slider
