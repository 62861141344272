import PropTypes from 'prop-types'
import deepEqual from 'fast-deep-equal'
import useToggle from '@/lib/hooks/useToggle'
import Dropdown from '@emcasa/ui-dom/components/Dropdown'
import styles from '@/styles/organisms/LandingHeader/styles.module.css'

const deepEqualStrategy = {
  isSelected: deepEqual,
  update: (_, value) => value
}

const Selects = ({onChange, onOpen, dropDowns, initialCity}) => {
  return (
    <div className={styles.ecLandingHeader__dropdowns}>
      {dropDowns.map((item, index) => {
        const focus = useToggle()

        return (
          <div
            key={`${item.options.length}-${index}`}
            className={styles.ecLandingHeader__dropdowns__item}
          >
            {item.legend && (
              <p className={styles.ecLandingHeader__dropdowns__legend}>
                {item.legend}
              </p>
            )}
            <Dropdown
              strategy={deepEqualStrategy}
              placeholder={item.placeholder}
              flexDirection="row-reverse"
              icon={focus.active ? 'chevron-up' : 'chevron-down'}
              initialValue={
                item.name === 'city' && initialCity ? initialCity.value : null
              }
              height={40}
              onChange={(value) => onChange({value, name: item.name})}
              onFocus={() => {
                if (focus && !focus.active) {
                  onOpen({name: item.name})
                  focus.on()
                }
              }}
              onBlur={focus.off}
              borderColor={item.buttonColor}
            >
              {item.options.map((opt, optIndex) => {
                return (
                  <Dropdown.Option key={optIndex} value={opt.value}>
                    {opt.label}
                  </Dropdown.Option>
                )
              })}
            </Dropdown>
          </div>
        )
      })}
    </div>
  )
}

Selects.propTypes = {
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  dropDowns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      legend: PropTypes.string,
      placeholder: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired
}

export default Selects
