import theme from '@/config/theme'
import Anchor from '@/components/shared/Anchor'

export const loggerAction = {
  onload: 'buyer-landing-page',
  onSelectsOpen: 'buyer-landing-select-open',
  onSelectsChange: 'buyer-landing-select-change',
  headerButton: 'buyer-landing-search-cta',
  headerLink: 'seller-cta-link',
  emcasaSectionLink: 'buyer-landing-link',
  specialistsSectionLink: 'buyer-landing-link',
  specialistsSectionButton: 'buyer-landing-specialists-button',
  feedListCardClick: 'buyer-landing-feed-list-card',
  feedListButtonClick: 'buyer-landing-feed-list-button',
  sellerSectionButton: 'buyer-landing-seller-button',
  blacknovemberButton: 'buyer-landing-blacknovember-button'
}

export const SELECT_CITY_VALUE_DEFAULT = '/sp/sao-paulo'

export const cityDropdown = {
  name: 'city',
  legend: 'Cidade',
  placeholder: 'Escolha uma cidade',
  buttonColor: theme.colors.pink300
}

export const priceDropdown = {
  name: 'price',
  legend: 'Imóveis até',
  placeholder: 'Escolha um valor',
  buttonColor: theme.colors.pink300,
  options: [
    {
      label: 'Até R$ 500.000',
      value: '/preco-max-500000'
    },
    {
      label: 'Até R$ 600.000',
      value: '/preco-max-600000'
    },
    {
      label: 'Até R$ 700.000',
      value: '/preco-max-700000'
    },
    {
      label: 'Até R$ 800.000',
      value: '/preco-max-800000'
    },
    {
      label: 'Até R$ 1.000.000',
      value: '/preco-max-1000000'
    },
    {
      label: 'Até R$ 2.000.000',
      value: '/preco-max-2000000'
    },
    {
      label: 'Acima de R$ 2.000.000',
      value: '/preco-min-2000000'
    }
  ]
}

export const HEADHELMET = {
  title: 'EmCasa - Imóveis à venda com os melhores especialistas imobiliários',
  description:
    'A EmCasa te ajuda a encontrar Apartamentos à Venda com tecnologia e um time de especialistas, tornando a compra de um imóvel mais transparente, ágil e segura.',
  url: 'https://emcasa.com/',
  image: `${process.env.CLOUDINARY_IMAGE_URL}/share/share-6`
}

export const LANDINGHEADER = {
  title: 'O melhor caminho até seu imóvel', // 'A EmCasa encontra o imóvel ideal para você'
  text: (
    <>
      <Anchor to="/especialistas-de-vendas">Nossos Especialistas</Anchor> te
      guiam da busca à entrega das chaves, de forma prática e segura.
    </>
  ),
  button: 'Buscar imóveis',
  anchor: 'Precisa vender um imóvel?'
}

export const LANDINGSECTION_STEPS = {
  label: 'Prático, Transparente e Seguro',
  title: 'Compre seu imóvel em 4 passos',
  content:
    'Um Especialista Imobiliário da EmCasa guiará você durante todo o processo.',
  list: [
    {
      title: 'Encontre o seu imóvel',
      text:
        'Milhares de imóveis com fotos profissionais e descrição detalhada para visitar só o que gostou e não ter surpresas na visita.'
    },
    {
      title: 'Visite o que gostou',
      text: (
        <>
          <Anchor
            to="/especialistas-de-vendas"
            onClick={() => logger.action('home-specialists-link')}
          >
            Nossos especialistas
          </Anchor>{' '}
          te acompanharão para garantir uma visita eficiente e segura. Se esse
          ainda não for o seu imóvel, eles farão recomendações baseadas no seu
          perfil.
        </>
      )
    },
    {
      title: 'Negociamos e resolvemos a burocracia para você',
      text:
        'Fazemos toda a negociação com o proprietário e oferecemos assessoria financeira para o processo de Financiamento Imobiliário.'
    },
    {
      title: 'Prático e  seguro',
      text:
        'Nosso time Jurídico especializado dá toda a assessoria para uma compra segura e sem surpresas.'
    }
  ]
}

export const LANDINGSECTION_SELLER = {
  label: 'Quero vender',
  title: 'Anuncie de graça e venda seu imóvel 3 vezes mais rápido',
  button: 'Faça seu anúncio',
  list: [
    'Fotos profissionais.',
    'Assessoria Jurídica em todo o processo.',
    'Divulgação do seu imóvel em nosso site, redes sociais e grandes portais.'
  ],
  img: {
    src: '/img/photos/living-room-01.webp',
    alt: 'Foto de uma sala de um apartamento'
  }
}
