import PropTypes from 'prop-types'

import Banner from '../'
const LSS = ({large = false, onClick}) => (
  <Banner
    large={large}
    darkBox
    image={{
      src: '/img/photos/banner-specialists.png',
      width: 204,
      height: 88
    }}
    title="É muito mais fácil comprar seu  imóvel com um especialista"
    text="Complete o seu perfil e receba recomendação de imóveis dos nossos especialistas"
    button={{
      label: 'Falar com especialista',
      to: '/falar-com-especialista',
      onClick: onClick,
      props: {
        active: false,
        dark: true,
        rounded: true,
        icon: '/img/icons/people-pink.svg'
      }
    }}
  />
)

LSS.propTypes = {
  large: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default LSS
