import {useEffect} from 'react'
import compose from 'recompose/compose'
import withRestoredScroll from '@/lib/hoc/withRestoredScroll'
import withUserProfile from '@/lib/hoc/withUserProfile'
import logger from '@/lib/logger'
import HeadHelmet from '@/components/shared/HeadHelmet'
import Shell from '@/components/layout/templates/Default'
import LandingHeader from '@/components/shared/LandingHeader'
import LandingSection from '@/components/shared/LandingSection'
import LandingList from '@/components/shared/LandingList'
import SectionPress from '@/components/shared/LandingSection/sections/Press'
import SectionPartnership from '@/components/shared/LandingSection/sections/Partnership'
import Anchor from '@/components/shared/Anchor'
import Button from '@/components/shared/Button/Link'
import styles from '@/styles/organisms/LandingSection/styles.module.css'
import composesStyles from '@/styles/_settings/composes.module.css'
import landingHeaderStyles from '@/styles/organisms/LandingHeader/styles.module.css'
import {
  HEADHELMET,
  LANDINGHEADER,
  LANDINGSECTION_STEPS,
  PARTNERSLIST,
  loggerAction
} from './constants'

const Landing = ({userProfile}) => {
  useEffect(() => {
    logger.action(loggerAction.onload, {userProfile})
  }, [])

  return (
    <Shell>
      <HeadHelmet {...HEADHELMET} />
      <LandingHeader
        small
        title={LANDINGHEADER.title}
        text={LANDINGHEADER.text}
        contents={[
          <Button
            active
            className={landingHeaderStyles.ecLandingHeader__item__button}
            to={{
              pathname: '/vender-imovel'
            }}
            onClick={() =>
              logger.action(loggerAction.headerButton, {
                userProfile,
                name: 'vender'
              })
            }
          >
            {LANDINGHEADER.button}
          </Button>,
          <Button
            className={landingHeaderStyles.ecLandingHeader__item__button}
            to={{
              pathname: '/avaliar/',
              state: {
                step: 'listing'
              }
            }}
            onClick={() => logger.action('seller-landing-evaluation-cta-link')}
          >
            Avaliar imóvel
          </Button>
        ]}
        footer={
          <Anchor
            to="/"
            onClick={() =>
              logger.action(loggerAction.headerLink, {name: 'comprar'})
            }
          >
            {LANDINGHEADER.anchor}
          </Anchor>
        }
      />
      <LandingSection
        textAlignCenter
        label={LANDINGSECTION_STEPS.label}
        title={LANDINGSECTION_STEPS.title}
        removeContentsPadding
        contents={[
          <p className={composesStyles.paddingx}>
            {LANDINGSECTION_STEPS.content}
          </p>,
          <LandingList name="passos" list={LANDINGSECTION_STEPS.list} />,
          <div
            className={styles.ecLandingSection__content__item__buttonWrapper}
          >
            <Button
              active
              to="/vender-imovel"
              className={styles.ecLandingSection__buttonCTA}
              onClick={() =>
                logger.action(loggerAction.stepSectionButton, {
                  name: 'Passo a passo',
                  to: '/vender-imovel'
                })
              }
            >
              {LANDINGSECTION_STEPS.button}
            </Button>
          </div>
        ]}
      />
      <SectionPartnership list={PARTNERSLIST} />
      <SectionPress />
    </Shell>
  )
}

export default compose(withUserProfile, withRestoredScroll)(Landing)
