import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '@/styles/organisms/LandingSection/styles.module.css'

const List = ({list, orderedList, responsiveColumns}) => {
  return (
    <ul
      className={classNames(styles.ecLandingSection__list, {
        [styles.ecLandingSection__list_responsiveColumns]: responsiveColumns
      })}
    >
      {list.map((item, index) => (
        <li
          key={index}
          className={classNames(styles.ecLandingSection__list__item, {
            [styles.ecLandingSection__list__item]: orderedList
          })}
        >
          {orderedList ? (
            <>
              <span className={styles.ecLandingSection__list__item__index}>
                {index + 1}
              </span>
              {item}
            </>
          ) : (
            <>
              <img
                className={styles.ecLandingSection__list__item__icon}
                src="/img/icons/tag-check.svg"
                alt="Ícone"
                width="18"
                height="12"
              />
              {item}
            </>
          )}
        </li>
      ))}
    </ul>
  )
}

List.propTypes = {
  list: PropTypes.array.isRequired,
  orderedList: PropTypes.bool,
  responsiveColumns: PropTypes.bool
}

export default List
