import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

export default (Component) =>
  function withRestoredScroll(props) {
    if (process.browser) {
      const {pathname} = useLocation()
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [pathname])
    }
    return <Component {...props} />
  }
