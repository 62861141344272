import Lazyload from 'react-lazyload'
import styles from '@/styles/organisms/LandingSection/styles.module.css'

const Video = ({src}) => (
  <Lazyload offset={50}>
    <iframe
      title="youtubeEmbed"
      className={styles.ecLandingSection__video}
      height="315"
      width="100%"
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Lazyload>
)

export default Video
