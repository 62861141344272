import {useEffect} from 'react'
import compose from 'recompose/compose'
import logger from '@/lib/logger'
import withUserProfile from '@/lib/hoc/withUserProfile'
import withRestoredScroll from '@/lib/hoc/withRestoredScroll'
import Shell from '@/components/layout/templates/Default'
import HeadHelmet from '@/components/shared/HeadHelmet'
import Button from '@/components/shared/Button/Link'
import Anchor from '@/components/shared/Anchor'
import BannerLSS from '@/components/shared/Banner/banners/LSS'
import LandingHeader from '@/components/shared/LandingHeader'
import LandingSection from '@/components/shared/LandingSection'
import LandingSectionVideo from '@/components/shared/LandingSection/contents/Video'
import SectionPress from '@/components/shared/LandingSection/sections/Press'
import styles from '@/styles/organisms/LandingSection/styles.module.css'
import {loggerAction, EMCASA_ABOUT_VIDEO} from './constants'

function About({userProfile}) {
  useEffect(() => {
    logger.action(loggerAction.onload, {userProfile})
  }, [])

  return (
    <Shell>
      <HeadHelmet
        title="Conheça a EmCasa. A melhor experiência
        na compra e venda de imóveis"
        description="A EmCasa te ajuda a encontrar Apartamentos à Venda com tecnologia e um time de especialistas, tornando a compra de um imóvel mais transparente, ágil e segura."
        url="https://emcasa.com/"
        image={`${process.env.CLOUDINARY_IMAGE_URL}/share/share-6`}
      />
      <LandingHeader
        title="Nascemos para mudar o mercado imobiliário"
        text="Nossa missão é fazer a compra mais importante da vida das pessoas ser um processo agradável, eficiente e seguro. Como deveria ser."
        contents={[<LandingSectionVideo src={EMCASA_ABOUT_VIDEO} />]}
      />
      <LandingSection
        label="O início"
        title="Como tudo começou"
        contentRight={
          <div className={styles.ecLandingSection__content__item}>
            <img
              className={styles.ecLandingSection__illustration}
              alt="Foto com os fundadores da EmCasa, Gustavo Vaz e Lucas Cardozo"
              src="/img/photos/vaz-lucajo-01.jpg"
            />
          </div>
        }
        contents={[
          <p>
            <Anchor
              onClick={() =>
                logger.action(loggerAction.link, {
                  userProfile,
                  name: 'Gustavo Vaz',
                  to: 'https://www.linkedin.com/in/gustavo-vaz/'
                })
              }
              href="https://www.linkedin.com/in/gustavo-vaz/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Gustavo
            </Anchor>{' '}
            é filho de mãe corretora de imóveis e cresceu conhecendo as dores do
            mercado imobiliário. Ficava inconformado com a ineficiência, dor de
            cabeça e a falta de segurança que o processo gerava, tanto para
            compradores e vendedores, como também para os corretores. Após ter
            construído carreira em startups renomadas, decidiu criar seu próprio
            negócio e foi fazer um MBA em Harvard para se aperfeiçoar. De volta
            ao Brasil em 2018, se juntou a{' '}
            <Anchor
              onClick={() =>
                logger.action(loggerAction.link, {
                  userProfile,
                  name: 'Gabriel Laet',
                  to: 'https://www.linkedin.com/in/gabriellaet/'
                })
              }
              href="https://www.linkedin.com/in/gabriellaet/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Gabriel
            </Anchor>{' '}
            e{' '}
            <Anchor
              onClick={() =>
                logger.action(loggerAction.link, {
                  userProfile,
                  name: 'Lucas Cardozo',
                  to: 'https://www.linkedin.com/in/lucas-cardozo-emcasa'
                })
              }
              href="https://www.linkedin.com/in/lucas-cardozo-emcasa/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Lucas
            </Anchor>
            , dois especialistas em tecnologia e operações, com uma missão:
            transformar o mercado imobiliário, oferecendo um processo de compra
            e venda agradável, simples e seguro para todos, como deveria ser.
          </p>
        ]}
      />
      <LandingSection
        label="Reconhecimento"
        title="O crescimento da EmCasa"
        contentLeft={
          <div className={styles.ecLandingSection__content__item}>
            <img
              className={styles.ecLandingSection__illustration}
              alt="Foto com o Gustavo Vaz, um dos fundadores da EmCasa"
              src="/img/photos/vaz-01.jpg"
            />
          </div>
        }
        contents={[
          <p>
            Em 2021, a <strong>EmCasa</strong> foi escolhida pela EXAME como uma
            das <strong>50 empresas que estão mudando o Brasil.</strong> Em
            2020, foi nomeada pela PEGN como uma das{' '}
            <strong>100 startups mais promissoras do país.</strong> A empresa
            participou por programas de aceleração de{' '}
            <strong>Harvard e da Endeavor.</strong>
          </p>
        ]}
      />
      {!userProfile?.requested_service && (
        <BannerLSS
          large
          onClick={() => logger.action(loggerAction.lss, {userProfile})}
        />
      )}
      <SectionPress />
      <LandingSection
        reverse
        label="Vagas abertas"
        title="Trabalhe em uma das startup mais desejadas do Brasil"
        contents={[
          <p>
            Fomos escolhidos pelo Linkedin como a{' '}
            <strong>8ª startup mais desejada para trabalhar no Brasil.</strong>{' '}
            Venha fazer parte de uma empresa com cultura inovadora e time
            excepcional.
          </p>,
          <Button
            active
            className={styles.ecLandingSection__buttonCTA}
            href="https://www.linkedin.com/company/emcasa/jobs/"
            target="_blank"
            rel="external noopener noreferrer"
            onClick={() =>
              logger.action(loggerAction.button, {
                userProfile,
                name: 'Vagas abertas',
                to: 'https://www.linkedin.com/company/emcasa/jobs/'
              })
            }
          >
            Trabalhe conosco
          </Button>
        ]}
        contentRight={
          <div className={styles.ecLandingSection__content__item}>
            <img
              className={styles.ecLandingSection__illustration}
              alt="Ilustração"
              src="/img/photos/job-opportunity.jpg"
            />
          </div>
        }
      />
      <LandingSection
        reverse
        label="Blog da emcasa"
        title="Confira as melhores dicas sobre compra e venda de imóveis no Brasil."
        contents={[
          <p>
            Todas as informações que você precisa saber para comprar ou vender
            seu imóvel, com mais segurança.
          </p>,
          <Button
            active
            className={styles.ecLandingSection__buttonCTA}
            to="/blog?nocache=sw"
            onClick={() =>
              logger.action(loggerAction.button, {
                userProfile,
                name: 'EmCasa Blog',
                to: '/blog'
              })
            }
          >
            Visite nosso Blog
          </Button>
        ]}
        contentLeft={
          <div className={styles.ecLandingSection__content__item}>
            <img
              className={styles.ecLandingSection__illustration}
              alt="Ilustração"
              src="/img/photos/blog.jpg"
            />
          </div>
        }
      />
    </Shell>
  )
}

export default compose(withUserProfile, withRestoredScroll)(About)
