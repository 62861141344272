import {useEffect, useState} from 'react'
import compose from 'recompose/compose'
import {getUserGeoDataCity} from '@/lib/user'
import {getCitiesFromDistricts} from '@/lib/districts'
import logger from '@/lib/logger'
import withUserProfile from '@/lib/hoc/withUserProfile'
import withDistricts from '@/lib/hoc/withDistricts'
import withRestoredScroll from '@/lib/hoc/withRestoredScroll'
import Shell from '@/components/layout/templates/Default'
import HeadHelmet from '@/components/shared/HeadHelmet'
import Anchor from '@/components/shared/Anchor'
import Button from '@/components/shared/Button/Link'
import LandingHeader from '@/components/shared/LandingHeader'
import LandingSection from '@/components/shared/LandingSection'
import SectionPress from '@/components/shared/LandingSection/sections/Press'
import LandingList from '@/components/shared/LandingList'
import List from './components/List'
import Selects from './components/Selects'
import {
  loggerAction,
  SELECT_CITY_VALUE_DEFAULT,
  cityDropdown,
  priceDropdown,
  HEADHELMET,
  LANDINGHEADER,
  LANDINGSECTION_STEPS,
  LANDINGSECTION_SELLER
} from './constants'
import landingSectionStyles from '@/styles/organisms/LandingSection/styles.module.css'
import landingHeaderStyles from '@/styles/organisms/LandingHeader/styles.module.css'
import composesStyles from '@/styles/_settings/composes.module.css'

function HomePage({districts, userProfile, match}) {
  const [initialCityPath, setInitialCityPath] = useState(
    SELECT_CITY_VALUE_DEFAULT
  )
  const [selectCity, setSelectCity] = useState()
  const [selectPrice, setSelectPrice] = useState()

  const dropdownCities = [
    {
      ...cityDropdown,
      options: districts
        ? getCitiesFromDistricts(districts).map((item) => {
            return {
              label: item.name,
              value: `/${item.state}/${item.slug}`
            }
          })
        : []
    }
  ]

  const dropdowns = dropdownCities
  dropdowns.push(priceDropdown)

  const userCity =
    match && match.params && match.params.city
      ? match.params.city
      : getUserGeoDataCity()

  const initialCity =
    userCity &&
    dropdowns
      .find((i) => i.name === 'city')
      .options.find(({label}) => label.indexOf(userCity) > -1)

  const searchRedirect = {
    pathname: `/imoveis${selectCity || initialCityPath}${selectPrice || ''}`,
    state: {scroll: 0}
  }

  function onSelectsChange({name, value}) {
    name === 'city' ? setSelectCity(value) : setSelectPrice(value)

    logger.action(loggerAction.onSelectsChange, {name, [name]: value})
  }

  function onSelectsOpen(value) {
    logger.action(loggerAction.onSelectsOpen, {...value})
  }

  useEffect(() => {
    if (districts) {
      const initialUserCity =
        userCity && districts?.find(({city}) => city.indexOf(userCity) > -1)

      setInitialCityPath(
        (initialUserCity &&
          `/${initialUserCity?.stateSlug}/${initialUserCity?.citySlug}/`) ||
          SELECT_CITY_VALUE_DEFAULT
      )
    }
  }, [districts])

  useEffect(() => {
    logger.action(loggerAction.onload, {userProfile})
  }, [])

  return (
    <Shell>
      <HeadHelmet {...HEADHELMET} />
      <LandingHeader
        title={LANDINGHEADER.title}
        text={LANDINGHEADER.text}
        contents={[
          <Selects
            onChange={onSelectsChange}
            onOpen={onSelectsOpen}
            dropDowns={dropdowns}
            initialCity={initialCity}
          />,
          <Button
            active
            className={landingHeaderStyles.ecLandingHeader__item__button}
            to={searchRedirect}
            icon="/img/icons/search-white.svg"
            onClick={() =>
              logger.action(loggerAction.headerButton, {
                url: searchRedirect,
                userProfile
              })
            }
          >
            {LANDINGHEADER.button}
          </Button>
        ]}
        footer={
          <Anchor
            to="/vender"
            onClick={() =>
              logger.action(loggerAction.headerLink, {name: 'vender'})
            }
          >
            {LANDINGHEADER.anchor}
          </Anchor>
        }
      />
      <LandingSection
        removeContentsPadding
        textAlignCenter
        label={LANDINGSECTION_STEPS.label}
        title={LANDINGSECTION_STEPS.title}
        contents={[
          <p className={composesStyles.paddingx}>
            {LANDINGSECTION_STEPS.content}
          </p>,
          <LandingList name="passos" list={LANDINGSECTION_STEPS.list} />
        ]}
      />
      <LandingSection
        label={LANDINGSECTION_SELLER.label}
        title={LANDINGSECTION_SELLER.title}
        contents={[
          <List list={LANDINGSECTION_SELLER.list} />,
          <Button
            active
            className={landingSectionStyles.ecLandingSection__buttonCTA}
            to="/vender"
            onClick={() =>
              logger.action(loggerAction.sellerSectionButton, {
                name: 'Quero vender'
              })
            }
          >
            {LANDINGSECTION_SELLER.button}
          </Button>
        ]}
        contentLeft={
          <div className={landingSectionStyles.ecLandingSection__content__item}>
            <img
              className={landingSectionStyles.ecLandingSection__illustration}
              src={LANDINGSECTION_SELLER.img.src}
              alt={LANDINGSECTION_SELLER.img.alt}
              width="944"
              height="614"
            />
          </div>
        }
      />
      <SectionPress hideButton hideLinks />
    </Shell>
  )
}

export default compose(
  withDistricts,
  withUserProfile,
  withRestoredScroll
)(HomePage)
