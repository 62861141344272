import PropTypes from 'prop-types'
import logger from '@/lib/logger'
import Button from '@/components/shared/Button/Link'
import styles from '@/styles/organisms/LandingSection/styles.module.css'

const Partners = ({list}) => {
  function handleClickButton(log) {
    logger.action(log)
  }

  return (
    <div className={styles.ecLandingSection__partners}>
      {list.map((item, index) => {
        return (
          <div className={styles.ecLandingSection__partners__item} key={index}>
            <h3 className={styles.ecLandingSection__partners__item__title}>
              {item.title}
            </h3>
            <p className={styles.ecLandingSection__partners__item__text}>
              {item.text}
            </p>
            {item.button && (
              <Button
                href={item.button.href}
                to={item.button.to}
                target={item.button.href ? '_blank' : null}
                rel={item.button.href ? 'external noopener noreferrer' : null}
                className={styles.ecLandingSection__buttonCTA}
                onClick={() => handleClickButton(item.button.log)}
              >
                {item.button.label}
              </Button>
            )}
          </div>
        )
      })}
    </div>
  )
}

Partners.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      button: PropTypes.shape({
        to: PropTypes.string,
        label: PropTypes.string
      })
    })
  ).isRequired
}

export default Partners
