import {useEffect} from 'react'
import compose from 'recompose/compose'
import logger from '@/lib/logger'
import withUserProfile from '@/lib/hoc/withUserProfile'
import withRestoredScroll from '@/lib/hoc/withRestoredScroll'
import Shell from '@/components/layout/templates/Default'
import HeadHelmet from '@/components/shared/HeadHelmet'
import LandingHeader from '@/components/shared/LandingHeader'
import LandingSection from '@/components/shared/LandingSection'
import LandingSectionVideo from '@/components/shared/LandingSection/contents/Video'
import SectionBuy from '@/components/shared/LandingSection/sections/Buy'
import LandingList from '@/components/shared/LandingList'
import ButtonLink from '@/components/shared/Button/Link'
import {
  loggerAction,
  HEADHELMET,
  LANDINGHEADER,
  LANDINGSECTION_STEPS,
  LANDINGSECTION_SPECIALISTS,
  SPECIALISTS_VIDEO
} from './constants'
import styles from './styles.module.css'

function Specialists({userProfile}) {
  useEffect(() => {
    logger.action(loggerAction.onload, {userProfile})
  }, [])

  return (
    <Shell>
      <HeadHelmet {...HEADHELMET} />
      <LandingHeader
        title={LANDINGHEADER.title}
        text={LANDINGHEADER.text}
        contents={[
          <>
            {!userProfile?.requested_service && (
              <div
                className={styles.ecTemplateSpecialists__headerButtonWrapper}
              >
                <ButtonLink
                  dark
                  rounded
                  icon="/img/icons/people-pink.svg"
                  to="/falar-com-especialista"
                  onClick={() => logger.action(loggerAction.lss, {userProfile})}
                >
                  Falar com especialista
                </ButtonLink>
              </div>
            )}
            <LandingSectionVideo src={SPECIALISTS_VIDEO} />
          </>
        ]}
      />
      <LandingSection
        label={LANDINGSECTION_STEPS.label}
        title={LANDINGSECTION_STEPS.title}
        removeContentsPadding
        contents={[
          <LandingList
            oddColumn={true}
            list={LANDINGSECTION_STEPS.list}
            name="como_funciona"
          />
        ]}
      />
      <LandingSection
        label={LANDINGSECTION_SPECIALISTS.label}
        title={LANDINGSECTION_SPECIALISTS.title}
        contents={LANDINGSECTION_SPECIALISTS.contents}
        contentLeft={LANDINGSECTION_SPECIALISTS.contentLeft}
      />
      <SectionBuy />
    </Shell>
  )
}

export default compose(withUserProfile, withRestoredScroll)(Specialists)
