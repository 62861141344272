import PropTypes from 'prop-types'
import LandingSection from '@/components/shared/LandingSection'
import Partners from './partners'

const Partnership = ({label, title, list}) => (
  <LandingSection
    label={label || 'Renda extra'}
    title={
      title || (
        <>
          Com a EmCasa você pode ganhar dinheiro indicando imóveis à venda.
          <br />
          Cadastre-se e comece a indicar!
        </>
      )
    }
    contents={[<Partners list={list} />]}
  />
)

Partnership.propTypes = {
  label: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      button: PropTypes.shape({
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        href: PropTypes.string,
        label: PropTypes.string,
        log: PropTypes.log
      })
    })
  ).isRequired
}

export default Partnership
