import PropTypes from 'prop-types'
import logger from '@/lib/logger'
import LandingSection from '@/components/shared/LandingSection'
import LandingList from '@/components/shared/LandingList'
import Button from '@/components/shared/Button/Link'
import {midiaList} from './constants'
import styles from '@/styles/organisms/LandingSection/styles.module.css'

const Press = ({hideLinks, hideButton}) => {
  const list = hideLinks
    ? midiaList.reduce((acc, item) => {
        acc.push({
          icon: item.icon,
          text: item.text
        })
        return acc
      }, [])
    : midiaList

  return (
    <LandingSection
      label="EmCasa na mídia"
      title="O que estão falando sobre a gente"
      removeContentsPadding
      contents={[
        <LandingList name="press" list={list} />,
        !hideButton ? (
          <div
            className={styles.ecLandingSection__content__item__buttonWrapper}
          >
            <Button
              href="mailto:imprensa@emcasa.com"
              target="_blank"
              rel="external noopener noreferrer"
              onClick={() => logger.action('press-mailto-link')}
            >
              Entre em contato conosco
            </Button>
          </div>
        ) : null
      ]}
    />
  )
}

Press.propTypes = {
  hideLinks: PropTypes.bool,
  hideButton: PropTypes.bool
}

export default Press
