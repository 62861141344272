import {memo} from 'react'
import compose from 'recompose/compose'
import withDistricts from '@/lib/hoc/withDistricts'
import {getCitiesFromDistricts} from '@/lib/districts'
import logger from '@/lib/logger'
import LandingSection from '@/components/shared/LandingSection'
import LandingSectionVideo from '@/components/shared/LandingSection/contents/Video'
import Anchor from '@/components/shared/Anchor'
import Button from '@/components/shared/Button/Link'
import styles from '@/styles/organisms/LandingSection/styles.module.css'

const Buy = ({districts}) => {
  const cities = getCitiesFromDistricts(districts, true)
  return (
    <LandingSection
      label="Encontre seu imóvel"
      title="Onde você quer morar?"
      contents={[
        <p>
          Conheça nossas casas e apartamentos à venda
          {cities && cities.length
            ? cities.map((item, index) => (
                <>
                  {index === 0
                    ? ' em '
                    : index < cities.length - 1
                    ? ', '
                    : ' e '}
                  <Anchor
                    key={index}
                    to={{
                      pathname: `/imoveis/${item.state}/${item.slug}`,
                      state: {
                        scroll: 0
                      }
                    }}
                    title={`Ver imóveis em ${item.name}`}
                  >
                    {item.name}
                  </Anchor>
                </>
              ))
            : null}
          .
        </p>,
        <Button
          active
          className={styles.ecLandingSection__buttonCTA}
          to="/imoveis"
          onClick={() => logger.action('buy-cta-link')}
        >
          Encontrar imóveis
        </Button>
      ]}
      contentRight={
        <div className={styles.ecLandingSection__content__item}>
          <LandingSectionVideo src="https://www.youtube.com/embed/xEXt75Qy8_0?rel=0" />
        </div>
      }
    />
  )
}

export default compose(memo, withDistricts)(Buy)
