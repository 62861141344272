import logger from '@/lib/logger'

const loggerAction = 'press-link'

export const midiaList = [
  {
    icon: '/img/midia/epoca-negocios.jpg',
    text: (
      <q>
        <strong>EmCasa capta R$110 milhões e mira expansão</strong>
      </q>
    ),
    link: {
      label: 'Ler matéria',
      href:
        'https://epocanegocios.globo.com/Startup/noticia/2021/07/emcasa-capta-r-110-milhoes-e-mira-expansao-no-aquecido-mercado-das-proptechs.html',
      target: '_blank',
      onClick: () => logger.action(loggerAction, {name: 'epoca'})
    }
  },
  {
    icon: '/img/midia/exame.jpg',
    text: (
      <q>
        <strong>
          Proptech que reinventa papel do corretor, EmCasa recebe aporte
          milionário
        </strong>
      </q>
    ),
    link: {
      label: 'Ler matéria',
      href: 'https://exame.com/pme/proptech-emcasa-corretores-aporte/',
      target: '_blank',
      onClick: () => logger.action(loggerAction, {name: 'exame'})
    }
  },
  {
    icon: '/img/midia/infomoney.jpg',
    text: (
      <q>
        <strong>
          Site de imóveis aposta em corretores com salário fixo como modelo de
          negócio
        </strong>
      </q>
    ),
    link: {
      label: 'Ler matéria',
      href:
        'https://www.infomoney.com.br/do-zero-ao-topo/emcasa-site-de-imoveis-que-aposta-em-corretores-com-salario-fixo-recebe-r-110-milhoes/',
      target: '_blank',
      onClick: () => logger.action(loggerAction, {name: 'infomoney'})
    }
  },
  {
    icon: '/img/midia/forbes.png',
    text: (
      <q>
        <strong>
          Plataforma de busca de imóvel vai além e lança facilitação de crédito
          e serviços jurídicos
        </strong>
      </q>
    ),
    link: {
      label: 'Ler matéria',
      href:
        'https://forbes.com.br/colunas/2020/02/plataforma-de-busca-de-imovel-vai-alem-e-lanca-facilitacao-de-credito-e-servicos-juridicos/',
      target: '_blank',
      onClick: () => logger.action(loggerAction, {name: 'forbes'})
    }
  }
]
